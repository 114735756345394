import React from 'react';
import styled from "styled-components";
import { BREAKPOINT } from '@/utils/constants';
import HeroOfferBox from './_hero-offer-box';
import { Link } from '@latitude/link';
import { BUTTON_STYLE } from '@latitude/core/utils/constants';
import Section from '@latitude/section';


const StyledImage = styled.img`
  max-width: 610px;
  height: auto;
  object-fit: cover;
  object-position: left;
  position: relative;
  
  /* By default, no shift */
  left: 0;

  /* When the screen width is less than the image + content width, shift left */
  @media (min-width: ${BREAKPOINT.LG}) {
    left: calc((100% - 620px)); 
  }

  @media (max-width: ${BREAKPOINT.MD}) {
    left: calc((100% - 520px)); 
  }

  @media (max-width: ${BREAKPOINT.SM}) {
    display: none;
  }
`;

const StyledHeroOfferBoxWrapper = styled.div`
  @media (max-width: ${BREAKPOINT.SM}) {
    display: none;
  }
`;

const HiddenOnDesktop = styled.div`
  @media (min-width: ${BREAKPOINT.MD}) {
    display: none;
  }
`;

const StyledCreditCardDetails = styled.div`
  display: flex;
  justify-content: space-between;
  whitespace: break-word;
`;

const StyledCreditCostDetails = styled.div`
  display: flex;
  p{
  width: 50%;
  }
`;

const StyledHeading = styled.h3`
  color: #0555C8;
  margin: 0.5rem 0;
`;

const StyledTitleHeading = styled.h3`
color: #0555C8;
margin: 0.5rem 0;
@media (max-width : ${BREAKPOINT.SM}){
font-size: 26px;
line-height: 1.5;
}
`;

const StyledText = styled.p`
  line-height: 20px;
  width: 90%;
  color: #6c757d;
  margin-top: 10px;
  white-space: pre-wrap;
  @media (max-width : ${BREAKPOINT.SM}){
  width: 80%;
}
`;

const ThemedHeroBanner = () => {
  return (
    <Section css={` 
     background: var(--White-grey-gradient, linear-gradient(180deg, #f8f8f7 0%, #edebe9 100%));
     @media(min-width :  ${BREAKPOINT.LG}) {
     max-height: 625px;
     overflow: hidden;
     }
     `}>
      <div className="row">
        {/* Left Column */}
        <div className="col-lg-5">
          <StyledImage
            src={require("../../../images/credit-cards/gem-visa-rich-banner.png").default}
            alt="Gem Visa"
          />
        </div>

        {/* Right Column */}
        <div className="col-lg-7 col-sm-12">
          {/* Top Row */}
          <div className="d-flex">
            <HiddenOnDesktop className="col-sm-4" css={"max-width: 110px;"}>
              <img
                src={require("../../../images/credit-cards/latitude-gem.png").default}
                alt="Latitude Gem"
                height= "140px"
                width="90px"
              />
            </HiddenOnDesktop>
            <div className="col-lg-12 col-sm-8 ml-0" css={`
                color: #0555C8;
                  @media (min-width : ${BREAKPOINT.MD}){
                  padding: 0px;
                  }
                `}>
              <h4 className="fw-bold mt-2 mb-2" css={`
                color: #0555C8;
                  @media (max-width : ${BREAKPOINT.SM}){
                  font-size: 16px;
                  line-height: 1.5;
                  }
                `}>Latitude Gem Visa</h4>
              <StyledTitleHeading >Shop smart with Interest Free</StyledTitleHeading>
            </div>
          </div>

          {/* Description */}
          <p className="text-muted">
          Spread the cost of everyday spending like groceries and dining with 6 months Interest 
          Free on purchases over $250. Plus, enjoy 6–60 months Interest Free plans at hundreds 
          of retailers on furniture, electronics, and more. Shop smarter, keep your cash where it counts.
          </p>

          {/* Offer Details */}
          <div className="row">
            <div className="col-lg-6">
              <StyledCreditCardDetails>
                <div>
                  <StyledHeading>0%</StyledHeading>
                  <StyledText>Interest on $250+ purchases for 6 months</StyledText>
                </div>
                <div>
                  <StyledHeading>0%</StyledHeading>
                  <StyledText>Interest Free plans from 6 – 60 months</StyledText>
                </div>
              </StyledCreditCardDetails>

              <StyledCreditCostDetails>
                <p className="text-muted">
                  <strong>Credit card fee</strong> <br /> $10.95 per month
                </p>
                <p className="text-muted">
                  <strong>Card purchase rate</strong> <br />
                  27.99% p.a.
                </p>
              </StyledCreditCostDetails>

              {/* Hidden on larger screens */}
              <HiddenOnDesktop className="col-sm-8 pl-0 pr-0">
                <HeroOfferBox />
              </HiddenOnDesktop>

              {/* Apply Button */}
              <div className="d-flex align-items-left mt-4">
                <Link
                  className="w-auto"
                  button={BUTTON_STYLE.PRIMARY}
                  href="https://cards.latitudefinancial.com/gem"
                  trackId="gem-visa-banner"
                >
                  Apply Now
                </Link>
              </div>
            </div>

            {/* Offer Box (Visible on larger screens) */}
            <StyledHeroOfferBoxWrapper className="col-lg-6">
              <HeroOfferBox />
            </StyledHeroOfferBoxWrapper>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default ThemedHeroBanner;
