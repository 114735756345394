import React from 'react';
import styled from "styled-components";

const OfferBox = styled.div`
  border-radius: 10px;
  background: linear-gradient(180deg, #f580c0 -15.16%, #c31b62 100%);
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  color: white;
  margin-top:10px;
  p {
    margin: 0;
    line-height: 1.5;
  }
  h4{
  color: white;
  }
`;

const Text = styled.span`
  font-size: 12px;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: -0.35px;
  color: white;
  margin-top: 10px;
`;

const LabelText = styled.span`
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 10px;
`;

const HeroOfferBox = () => (
  <OfferBox>
    <LabelText>★ Limited time offer</LabelText>
    <h4 className='mt-2 mb-2'>Get $200 credit back</h4>
    <p>
    Spend $1,000 or more each month on everyday purchases in your first 3 months of approval<sup>2</sup>, 
    and we’ll reward you with $200 credit back. 
    </p>
    <Text>
    T&Cs, monthly credit card fee and other charges apply. 
    Excludes long term Interest Free payment plans. 
    New customers only, must be approved by <strong>28 Feb 2025</strong>.
    </Text>
  </OfferBox>
);

export default HeroOfferBox;
